import React from 'react';
import styled from 'styled-components';
import i18n from '../../i18n';
import { Layout } from '../containers/layout';
import { Table } from '../components/table';
import { Column } from '../components/column';
import { DynamicComponent } from '../components/dynamic-component';

const Image = styled.img`
    max-width: 100%;
`;

export default ({ language }) => {
    const content = i18n[language || 'en'];

    return (
        <Layout content={content}>
            <Image src="/jus-10.webp" alt="" />
            <Table>
                <Column>
                    {content.pages.attorney.map(o => (
                        <DynamicComponent options={o} />
                    ))}
                </Column>
            </Table>
        </Layout>
    );
};
